/* Loader.css */

.fullpage-loader{
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: #88888888;
	top: 0;
	left: 0;
	z-index: 999999 !important;
}
.custom-loader-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
  }

  .custom-centering {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
  }

  .custom-loader-container svg {
	width: 5rem;
  }
  

  .custom-loader-container svg {
	width: 5rem;
  }
  
  .custom-loader-container .custom-left-circle {
	animation: rotate 3s linear infinite;
	transform-origin: 29.3% 50%;
  }
  
  .custom-loader-container .custom-right-circle {
	animation: rotate 3s linear infinite;
	transform-origin: 70.2% 50%;
  }
  
  @keyframes rotate {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
